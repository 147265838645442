import {
  useTranslate,
  IResourceComponentsProps,
  useList,
} from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Table,
  Col,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { IPriceZone, IRoom, IRoomType } from "interfaces";

export const PriceZoneEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const col_center_half_xs = { span: 24, offset: 0 }
  const col_center_full_lg = { span: 17, offset: 3 }

  const { formProps, saveButtonProps, queryResult } = useForm<IPriceZone>({ 'redirect': 'edit' });

  const { selectProps: roomSelectProps, queryResult: roomQueryResult } =
    useSelect<IRoom>({
      resource: "rooms",
      optionValue: "id",
      optionLabel: "name",
      debounce: 100,
      fetchSize: 100,
      filters: [
        { field: "pricezone_id", operator: "null", value: true },
      ],
    });

  const { data: roomtypesData, isLoading: roomtypesisLoading } =
    useList<IRoomType>({
      resource: "roomtypes",
    });

  const roomtypes = roomtypesData?.data?.sort(
    (a, b) => Number(b.number) - Number(a.number)
  );

  if (
    roomSelectProps?.loading ||
    roomQueryResult?.data?.data === undefined ||
    roomtypesisLoading ||
    roomtypes === undefined
  ) {
    return <></>;
  }

  const selected = queryResult?.data?.data?.rooms?.map((room: IRoom) => ({
    value: room?.id,
    label: room?.name,
  })) as unknown as { value: string; label: string };

  const priceg: {
    id: string;
    price: string;
    g: string;
    pricezone: string;
    roomtype: string;
  }[] = queryResult?.data?.data?.priceg || [];

  const groupedData: Record<string, any[]> = priceg.reduce(
    (acc: Record<string, any[]>, item) => {
      const gValue = item.g;
      if (!acc[gValue]) {
        acc[gValue] = [];
      }
      acc[gValue].push(item);
      return acc;
    },
    {}
  );

  const columns = [
    {
      title: "G",
      dataIndex: "g",
      key: "g",
      render: (g: number) => g,
    },
    ...roomtypes.map((roomType) => ({
      title: roomType.name,
      render: (_: any, record: any) => {
        const priceItem = groupedData[record.g]?.find(
          (item: any) => item?.roomtype === roomType?.id
        );

        const pricegId = priceg?.map((e) => e?.id)?.indexOf(priceItem?.id);

        return (
          <>
            <Form.Item name={["priceg", pricegId, "id"]} hidden>
              <Input type="number" />
            </Form.Item>

            <Form.Item
              name={["priceg", pricegId, "price"]}
              style={{ marginBottom: 0 }}
            >
              <Input type="number" />
            </Form.Item>
          </>
        );
      },
    })),
  ];

  const tableData = Object.entries(groupedData).map(
    ([g, items]: [string, any[]]) => {
      const row: any = {
        key: g,
        g: g,
      };
      return row;
    }
  );

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Col xs={col_center_half_xs} lg={col_center_full_lg}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label={t("products.name")}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("menu.rooms")}
            name={["rooms"]}
            getValueFromEvent={(values) => {
              return values?.map((id: number) => ({ id }));
            }}
            getValueProps={(values: any) => {
              return values;
            }}
          >
            <Select
              {...roomSelectProps}
              mode="multiple"
              style={{ width: "100%" }}
              optionFilterProp="children"
              autoClearSearchValue
              defaultValue={selected}
            >
            </Select>
          </Form.Item>

          <Form.Item label={t("Prices")}>
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={false}
              bordered
              rowKey="g"
            />
          </Form.Item>
        </Form>
      </Col>
    </Edit>
  );
};
