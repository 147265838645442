import { useModalForm, Modal, Form, Input, Typography, Space, InputNumber, DeleteButton } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { InputPlusMinus } from "components/inputs/inputplusminus";
import { IExtraBookingRoom } from "interfaces";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import React from "react";
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

interface EditExtrasModalProps {
  refetch: () => void;
}

export const EditExtrasModal = forwardRef<any, EditExtrasModalProps>(({ refetch }, ref) => {
  const t = useTranslate();
  const [inputSum, setInputSum] = useState(0);
  const { modalProps, formProps, form, queryResult, id, show, close, formLoading } = useModalForm<IExtraBookingRoom>({
    action: "edit",
    redirect: false,
    resource: "products/extrasbookingroom",
  });

  modalProps.title = <Title level={4}>{`${t("buttons.edit")} ${t("pages.booking.extras")}`}</Title>;

  useImperativeHandle(ref, () => ({
    show(id: string) {
      show(id);
    },
  }));

  useEffect(() => {
    form.resetFields();
  }, []);

  const getDaysArray = (start: any, end: any) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const maxTotNb = queryResult?.data?.data?.nb || 0;
  const bookDates = {
    start: queryResult?.data?.data?.booking_start,
    end: queryResult?.data?.data?.booking_end,
    days: getDaysArray(queryResult?.data?.data?.booking_start, queryResult?.data?.data?.booking_end),
  };
  const days = bookDates.days.map((d: any) => ({ day: d, qtt: 0 }));

  const handleInputChange = (value: number, fieldName: string) => {
    const currentValues = form.getFieldsValue(true).infos?.date_qtt || {};
    let updatedValues = { ...currentValues, [fieldName]: value };

    let newSum = Object.values(updatedValues).reduce((acc: number, val: any) => acc + (val || 0), 0);

    console.log(newSum)
    console.log(maxTotNb < newSum)
    if (maxTotNb < newSum) {
      updatedValues = { ...currentValues, [fieldName]: 0 };
      newSum = Object.values(updatedValues).reduce((acc: number, val: any) => acc + (val || 0), 0);

      form.setFields([
        {
          name: ["infos", "date_qtt", fieldName],
          value: 0,
        },
      ]);
    }
    setInputSum(newSum);
  };

  return (
    <Form {...formProps}>
      <Modal {...modalProps} afterClose={() => refetch()}>
        <React.Fragment>
          <div style={{ fontWeight: 700, paddingBottom: '1rem', paddingTop: '1rem' }}>
            {queryResult?.data?.data?.name}
          </div>
          <div style={{ marginTop: '1rem', color: inputSum >= maxTotNb ? 'red' : 'inherit' }}>
            {t(`Used: ${inputSum}/${maxTotNb}`, { max: maxTotNb, current: inputSum })}
          </div>

          <Space wrap>
            <Form.Item name={["extra_id"]} hidden>
              <Input />
            </Form.Item>

            {days.map(({ day }, index: number) => {
              const fieldName = dayjs(day).format('YYYY-MM-DD');
              return (
                <Form.Item
                  key={fieldName}
                  name={["infos", "date_qtt", fieldName]}
                  initialValue={0}
                >
                  <InputPlusMinus
                    buttons
                    integerOnly={true}
                    min={0}
                    label={dayjs(day).locale(t("short_lang")).format('DD/MM')}
                    onChange={(value: number) => handleInputChange(value, fieldName)}
                  />
                </Form.Item>
              );
            })}

            <div>
              <Form.Item name={["custom_unit_price"]}>
                <InputNumber
                  controls={false}
                  min={0}
                  addonAfter={'€'}
                  style={{ maxWidth: '7rem', marginTop: '1.4rem' }}
                />
              </Form.Item>
            </div>

            <Form.Item>
              {!queryResult?.data?.data?.is_paid && (
                <DeleteButton
                  size="small"
                  hideText
                  style={{ marginTop: '1.4rem' }}
                  recordItemId={queryResult?.data?.data?.id}
                  resourceNameOrRouteName="products/extrasbookingroom"
                  onSuccess={() => close()}
                />
              )}
            </Form.Item>
            <br></br>
          </Space>
        </React.Fragment>
      </Modal>
    </Form>
  );
});
