import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  Form,
  Input,
  Icons,
  Button,
  ListButton,
  DeleteButton,
} from "@pankod/refine-antd";
import { useNavigation } from '../../core/customNavigation';
import { IPriceZone } from "interfaces";
import { PlusSquareOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'

export const PriceZoneList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { create } = useNavigation()

  const { tableProps, sorter, searchFormProps } = useTable<IPriceZone, HttpError, IPriceZoneFilterVariables>({
      onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;
      filters.push(
          {
              field: "name",
              operator: "contains",
              value: name,
          },
      );
      return filters;
    },
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

    const isLoading = false;

  return (
    <List 
      title={t("menu.pricezones")}
      pageHeaderProps={{
        extra: [
          <Form 
            layout="horizontal"
            onValuesChange={() => searchFormProps.form?.submit()}
            key="page_header_extra_1"
            {...searchFormProps}
          >
            <Form.Item label="" name="name" style={{ marginBottom: 0 }}>
              <Input
                  placeholder="ID, Title, Content, etc."
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </Form>,
          <Button key="page_header_extra_2" onClick={(e) => create(t("menu.pricezones", e))}>+</Button>,

        ],
      }}
    >
      <Table
        {...tableProps} 
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
      >
        <Table.Column<IPriceZone>
          dataIndex="name"
          key="name"
          title={t("rooms.room")}
          render={(_, record) => {
            return(
              <Space>
                <TextField value={record?.name} />
                <EditButton hideText size="small" resourceNameOrRouteName="pricezones" recordItemId={record?.id}></EditButton>
                <DeleteButton hideText size="small" recordItemId={record.id} />
                </Space>
            )}}
  
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column<IPriceZone>

          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => {
          return(
            <Space>
              {record?.yieldanalysis?.[0]?.id && <EditButton size="small" resourceNameOrRouteName="yieldanalysis" recordItemId={record?.yieldanalysis?.[0]?.id}>{`${t("buttons.edit")} ${t("menu.yield")} ${dayjs(record?.yieldanalysis?.[0]?.dateref).locale(t("short_lang")).format('ddd D/M')}`}</EditButton>}
              {<ListButton size="small" resourceNameOrRouteName="yieldanalysis"></ListButton>}
              <Button href={`${t("menu.yield")}/create/${record.id}`} size="small" icon={<PlusSquareOutlined />}>{`${t("buttons.create")} ${t("menu.yield")}`}</Button>
              </Space>
          )}}
        />
      </Table>
    </List>
  );
};

interface IPriceZoneFilterVariables {
  name: string;
}
