import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
} from "@pankod/refine-antd";
import { IPriceZone } from "interfaces";

export const PriceZoneCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IPriceZone>({
    resource: 'pricezones',
    redirect: 'list'
  });

  return (
    <Create title={`${t('buttons.create')} ${t('pricezones.pricezone')}`}  saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("products.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
