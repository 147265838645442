import { useState } from "react";
import { useTranslate, IResourceComponentsProps, useApiUrl, useCustom, file2Base64 } from "@pankod/refine-core";
import {
    Alert,
    Button,
    Col,
    Edit,
    Form,
    FormInstance,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Tooltip,
    Typography,
    UploadFile,
    useForm,
    useSelect,
    getValueFromEvent,
} from "@pankod/refine-antd";
import { FormProps, Tabs, Upload } from 'antd';

import "react-mde/lib/styles/css/react-mde-all.css";

import { IEquipment, IInstruction, IJsonTxt, IRoom, IRoomText } from "interfaces";
import React from "react";
import { ExclamationCircleFilled, InfoCircleOutlined, InfoCircleTwoTone, PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { text } from "stream/consumers";
import { InputEditor } from "components/inputs/inputeditor";

const { Title, Text } = Typography;

const col_center_half_xs = { span: 24, offset: 0 }
const col_center_full_lg = { span: 12, offset: 6 }
export const RoomEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { formProps, saveButtonProps, queryResult, form, formLoading } = useForm<IRoom>({ 'redirect': 'edit' });
    const roomName = queryResult?.data?.data?.name

    const onFinish = (values: any) => {
        return (
            formProps.onFinish &&
            formProps.onFinish({
                // ...values,
                birth: Math.trunc(Number(form.getFieldValue('birth')) / 1_000),
            })
        );
    };

    return (
        <Edit saveButtonProps={saveButtonProps} title={`${t('buttons.edit')} ${roomName}`}>
            <Tabs defaultActiveKey="1" centered>
                <Tabs.TabPane forceRender tab={`${t('rooms.room')}`} key="1">
                    <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                        {!formLoading && <RoomForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish} />}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={`${t('rooms.texts.app.translate')}`} key="2">
                    <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                        {!formLoading && <LangTextRoomForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish} />}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={`${t('rooms.pictures')}`} key="8">
                    <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                        {!formLoading && <PicturesForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={`${t('rooms.localisation')}`} key="3">
                    <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                        {!formLoading && <RoomLocalisationForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish} />}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={`${t('related')}`} key="4">
                    <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                        {!formLoading && <RoomRelated form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish} />}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={`${t('rooms.access_instructions')}`} key="5">
                    <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                        {!formLoading && <LangInstructionForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish} />}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={`${t('rooms.tech')}`} key="6">
                    <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                        {!formLoading && <RoomTechForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish} />}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={`${t('rooms.tax')}`} key="7">
                    <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                        {!formLoading && <RoomTaxForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish} />}
                     </Col>
                </Tabs.TabPane>
            </Tabs>
        </Edit>
    );
};

interface FunctProps extends IResourceComponentsProps {
    formProps: any;
    form: FormInstance;
    queryResult: any;
    formLoading: any;
    onFinish({ }): any;
}

const RoomForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading, onFinish } = props
    
    return (
        <Form {...formProps} layout="vertical">

            <Form.Item label={t("rooms.name")} name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item label={<Text>{t("rooms.name_short")} <Tooltip title={t("rooms.name_short_alert")}><InfoCircleOutlined style={{ color: 'red' }} /></Tooltip></Text>} name="name_short" rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.start_hour")} name="start_hour">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.end_hour")} name="end_hour">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.edit.capacity_max")} name="capacity_max">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.edit.infant_allowed")} name="infant_allowed">
                <Input />
            </Form.Item>

            <Form.Item label={<React.Fragment><Text>{t("rooms.edit.color")}</Text> <div style={{ "backgroundColor": `#${queryResult?.data?.data?.color}`, "padding": "10px", "marginLeft": "5px" }}></div></React.Fragment>} name="color">
                {/* <Input onChange={(e,value) => updateColor(e,value)}/> */}
                <Input />
            </Form.Item>

            <Form.Item label={<React.Fragment><Text>{t("rooms.edit.color_1")}</Text> <div style={{ "backgroundColor": `#${queryResult?.data?.data?.color_1}`, "padding": "10px", "marginLeft": "5px" }}></div></React.Fragment>} name="color_1">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.edit.three_d_url")} name="three_d_url">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.edit.area")} name="area">
                <Input />
            </Form.Item>

        </Form>
    );
};

const { confirm } = Modal;


const RoomTechForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { formProps, form, queryResult, formLoading, onFinish } = props

    const { data: yp_room_ids_data } = useCustom<any>({
        url: `${apiUrl}/rooms/${queryResult?.data?.data?.id}/get_yeildplanet_free_ids`,
        method: "get",
    });

    const isLinked = queryResult?.data?.data?.yp_room_id == 0 ? false : true;
    const options_yp_room_ids = yp_room_ids_data?.data?.map((e: any) => ({ value: e.yp_room_id, hotel_id: e.yp_hotel_id, label: e.name }))

    let tmp = <></>
    const [tmpblock, setTmpblock] = useState<any>(null)
    const ypDisableHandler = () => {
        confirm({
            title: t("buttons.disable"),
            content: t("rooms.disable_yp_info"),
            icon: <ExclamationCircleFilled />,
            onOk() {
                setTmpblock(<YpDisableHandler queryResult={queryResult}></YpDisableHandler>)
            },
            onCancel() {
                return
            },
        });

    }

    return (
        <Form {...formProps} layout="vertical">

            <Form.Item
                label={t("rooms.is_enable")}
                name="is_enable"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>

            <Form.Item label={t("rooms.ip")} name="ip">
                <Input />
            </Form.Item>

            <Form.Item label={<><Text>{t("rooms.yieldplanet")}</Text>&nbsp;<Button danger hidden={!isLinked || tmpblock} onClick={() => ypDisableHandler()}>{t('buttons.disable')}</Button></>} name="yp_room_id">
                <Select options={options_yp_room_ids} onChange={(e: any, o: any) => form?.setFieldsValue({ 'yp_hotel_id': o?.hotel_id })} />
            </Form.Item>

            <Form.Item name="yp_hotel_id" hidden>
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.port")} name="port">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.door_number")} name="door_number">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.nac_iden")} name="nac_iden">
                <Input />
            </Form.Item>
            {tmpblock}
        </Form>
    );
};

interface YpProps extends IResourceComponentsProps {
    queryResult: any;
}

const YpDisableHandler: React.FC<YpProps> = (props) => {
    const { queryResult } = props
    const apiUrl = useApiUrl();
    console.log('YpDisableHandler')
    const { data: yp_room_ids_data } = useCustom<any>({
        url: `${apiUrl}/rooms/${queryResult?.data?.data?.id}/disable_yeildplanet`,
        method: "post",
    });

    return <></>
}

const RoomLocalisationForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading, onFinish } = props

    const { queryResult: pricezoneQueryResult } = useSelect({ resource: "pricezones" });

    return (
        <Form {...formProps} layout="vertical">

            <Form.Item label={t("rooms.code_porche")} name="code_porche">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.code_immeuble")} name="code_immeuble">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.code_etage")} name="code_etage">
                <Input />
            </Form.Item>

            <Row>
                <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${queryResult?.data?.data?.lat}%2C${queryResult?.data?.data?.lng}`}>
                    {`${t('position')}: lat ${queryResult?.data?.data?.lat} lng ${queryResult?.data?.data?.lng}`}
                </a>
            </Row>
            <br />

            <Form.Item label={t("rooms.address")} name="address">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.city")} name="city">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.zip")} name="zip">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.country")} name="country">
                <Input />
            </Form.Item>

            <Form.Item label={t("rooms.pricezone")} name="pricezone">
                <Select options={pricezoneQueryResult?.data?.data?.map(item => ({ value: item.id, label: item.name }))} />
            </Form.Item>

        </Form>
    );
};

interface LangTextRoomFormProps extends IResourceComponentsProps {
    formProps: any;
    form: FormInstance;
    queryResult: any;
    formLoading: any;
    onFinish({ }): any;
}

const LangTextRoomForm: React.FC<LangTextRoomFormProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading, onFinish } = props
    const roomtexts: [IRoomText] = queryResult?.data?.data?.texts || [];

    return (
        <Form {...formProps} layout="vertical">
            {roomtexts?.map((roomtext, id) => <TextRoomForm id={id} roomtext={roomtext} key={`TextRoomForm_${id}`} />)}
        </Form>
    )
}

interface TextRoomFormProps extends IResourceComponentsProps {
    roomtext: IRoomText;
    id: any;
}


const TextRoomForm: React.FC<TextRoomFormProps> = (props) => {
    const t = useTranslate();
    const { roomtext, id } = props

    const [newInfobulletTxt, setNewInfobullet] = useState<string[]>([])
    const [newDesc, setNewDesc] = useState<string[]>([])

    const rules_35 = [
        {
            max: 35,
        }
    ]
    const idenMaxIBullet = Number(roomtext?.json_txt?.infobullet?.length)
    const idenMaxDesc = Number(roomtext?.json_txt?.desc?.length)

    const addNewDescField = () => {
        setNewDesc([...newDesc, '']);
    }
    const addNewInfobulletField = () => {
        setNewInfobullet([...newInfobulletTxt, '']);
    }


    return (
        <React.Fragment>
            <Title level={4}>{`${t('rooms.texts.app.translate')} ${roomtext?.lang?.short_name}`}</Title>

            <Form.Item label={t("rooms.texts.app.short_desc")} name={['texts', id, 'json_txt', 'short_desc']} rules={rules_35}>
                <Input />
            </Form.Item>

            <Title level={5}>{`${t('rooms.texts.app.infobullet')}`} <Button icon={<PlusOutlined />} size="small" onClick={addNewInfobulletField} className="antd-btn-info" >{t('buttons.add')}</Button></Title>
            {roomtext?.json_txt?.infobullet?.map((_, iden) => {
                return (
                    <Form.Item name={['texts', id, 'json_txt', 'infobullet', iden]} key={`texts${id}json_txtinfobullet${iden}`} rules={rules_35}>
                        <Input />
                    </Form.Item>)
            })}
            {newInfobulletTxt?.map((_, iden) => {
                return (
                    <Form.Item name={['texts', id, 'json_txt', 'infobullet', iden + idenMaxIBullet]} key={`texts${id}json_txtinfobullet${iden + idenMaxIBullet}`} rules={rules_35}>
                        <Input />
                    </Form.Item>)
            })}

            <Title level={5}>{`${t('rooms.texts.app.desc')}`}  <Button icon={<PlusOutlined />} size="small" onClick={addNewDescField} className="antd-btn-info" >{t('buttons.add')}</Button></Title>
            {roomtext?.json_txt?.desc?.map((_, iden) => {
                return (
                    <Form.Item name={['texts', id, 'json_txt', 'desc', iden]} key={`texts${id}json_txtdesc${iden}`} rules={[{ max: 2000 }]}>
                        <Input.TextArea rows={5} style={{ height: 120, resize: 'none' }} showCount />
                    </Form.Item>)
            })}
            {newDesc?.map((_, iden) => {
                return (
                    <Form.Item name={['texts', id, 'json_txt', 'desc', iden + idenMaxDesc]} key={`texts${id}json_txtdesc${iden + idenMaxDesc}`} rules={[{ max: 2000 }]}>
                        <Input.TextArea rows={5} showCount />
                    </Form.Item>)
            })}


            <Form.Item hidden name={['texts', id, 'id']} />
        </React.Fragment>
    )
}

const RoomRelated: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading, onFinish } = props

    const { selectProps: roomSelectProps, queryResult: roomQueryResult } = useSelect<IRoom>({
        resource: 'equipments',
        optionValue: "id",
        optionLabel: "label",
        debounce: 100,
        fetchSize: 100,
    });

    if (roomSelectProps?.loading == true || roomQueryResult?.data?.data === undefined) {
        return (<></>)
    }

    const selected = queryResult?.data?.data?.equipments?.map((equipment: IEquipment) => ({ value: equipment.id, label: undefined })) as unknown as { value: string; label: string; }

    const selectOptions = [
        {
            value: 'all',
            // label: t('menu.selectAll'), // Adjust this label according to your language
            label: "All", // Adjust this label according to your language
        },
        ...roomQueryResult?.data?.data?.map((item: any) => ({
            value: item.id,
            label: item.label,
        })),
    ];

    const handleSelectAll = (allSelected: any) => {
        if (allSelected.includes('all')) {
            // If "Select All" is selected, return all available values except "Select All"
            return roomQueryResult?.data?.data?.map((item) => item.id) || [];
        }
        return allSelected;
    };

    return (
        <Form {...formProps} layout="vertical">

            <Form.Item
                label={t("menu.equipments")}
                name={["equipments"]}
                getValueFromEvent={(values,) => {
                    return values?.map((id: number) => ({ id }));
                }}
                getValueProps={(values: any) => {
                    return values
                }}
            >
                <Select
                    {...roomSelectProps}
                    mode="multiple"
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    autoClearSearchValue
                    defaultValue={selected}
                >
                    {selectOptions.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
                {/* <Select
                    {...roomSelectProps}
                    mode="multiple"
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    autoClearSearchValue
                    defaultValue={selectOptions}
                /> */}
            </Form.Item>

        </Form>
    );
};


// 
// Access Instructions
// 

const LangInstructionForm: React.FC<LangTextRoomFormProps> = (props) => {
    const { formProps, form, queryResult, formLoading, onFinish } = props
    const instructions: [IInstruction] = queryResult?.data?.data?.instructions || [];
    
    return (
        <Form {...formProps} layout="vertical">
            {instructions?.map((instruction, id) => <InstructionForm id={id} instruction={instruction} form={form} />)}
        </Form>
    )
}

interface InstructionFormProps extends IResourceComponentsProps {
    form: FormInstance;
    instruction: IInstruction;
    id: number;
}


const InstructionForm: React.FC<InstructionFormProps> = ({id, instruction, form}) => {
    const t = useTranslate();
    
    const onChange = (e: any) => {
        form?.setFields([{name: ['instructions', id, 'access_payload'], value: e}])
        return (e)
    } 

    return (
        <>
            <Form.Item hidden name={['instructions', id, 'id']} />
            <Form.Item hidden name={['instructions', id, 'access_payload']} />

            <Form.Item label={`${t('rooms.texts.app.translate')} ${instruction?.lang?.short_name}`} name={['instructions', id, 'access_html']} key={`instructions${id}json_txtde`} rules={[{ max: 2000 }]}>
                <InputEditor onChange={onChange} formats={['color', 'list']} modules={{toolbar: {container: [[{ color: ['black', '#c15842'] }],[{ list: "ordered" },],["clean"],]}}} />
            </Form.Item>
        </>
    )
}

const RoomTaxForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { formProps, form, queryResult, formLoading, onFinish } = props;
    const { data: taxeSejoursData, isLoading } = useCustom<any>({
        url: `${apiUrl}/touristtax`,
        method: "get",
    });
    const taxeOptions = taxeSejoursData?.data?.map((taxe: any) => ({
        value: taxe.id,
        label: taxe.name,
    }));
    const currentTaxeSejourId = queryResult?.data?.data?.taxe_sejour?.id;
    return (
        <Form {...formProps} layout="vertical">
            <Form.Item
                label={t("rooms.taxe_sejour_edit")}
                name="taxe_sejour_id"
                rules={[{ required: true, message: t("validation.required") }]}
                initialValue={currentTaxeSejourId}
            >
                <Select
                    options={taxeOptions}
                    loading={isLoading}
                    placeholder={t("select.placeholder")}
                />
            </Form.Item>
        </Form>
    );
};

interface PicturesFormProps extends IResourceComponentsProps {
    formProps: FormProps<{}>;
    form: FormInstance<{}>;
    queryResult: any;
    formLoading: boolean;
}

export const PicturesForm: React.FC<PicturesFormProps> = (props) => {
    const t = useTranslate();
    const { formProps, queryResult, form, formLoading } = props

    const rules_35 = [
        {
            max: 35,
        }
    ]

    const validatePictures = async (rule: any, value: any, callback: any) => {
        const base64Files = [];

        for (const file of value) {
            if (file.originFileObj) {
                const path = await file2Base64(file);

                base64Files.push({
                    ...file,
                    path,
                    number: 0,
                });
            } else {
                base64Files.push(file);
            }
        }

        form.setFieldsValue({ pictures: base64Files });
    };

    interface myfile extends UploadFile<any> {
        id: number;
    }
    // return(null)
    return (
        <Form  {...props.formProps} layout="vertical">

            <Form.Item label="">
                <Form.Item
                    name={['pictures']}
                    valuePropName="fileList"
                    getValueFromEvent={getValueFromEvent}
                    rules={[{ validator: validatePictures }]}
                    noStyle
                >
                    <Upload.Dragger
                        listType="picture-card"
                        beforeUpload={() => false}
                        accept="image/jpeg,image/png,image/gif"
                        itemRender={(originNode, file, currFileList) => {
                            const currId = (file as myfile)?.id ?? file.uid
                            const currFormKey = currFileList.findIndex((item) => ((item as myfile).id == currId || item.uid == `${currId}`))
                            return (
                                <div>
                                    {originNode}
                                    <Form.Item
                                        label='number'
                                        name={["pictures", currFormKey, "number"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input  placeholder={t("number")} ></Input>
                                    </Form.Item>
                                    <Form.Item
                                        label='default'
                                        name={["pictures", currFormKey, "is_default"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input  placeholder={t("is_default")} />
                                    </Form.Item>
                                </div>
                            )
                        }}
                    >
                        <p className="ant-upload-text">Drag & drop a file in this area</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
            <style>{`.ant-upload-list-picture-card-container {display: contents}`}</style>
        </Form>
    )
}